<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6.5"
      cy="6.5"
      r="6"
      :stroke="color === 'danger' ? '#DF7244' : '#5B6D79'"
    />
    <rect
      x="6"
      y="3"
      width="1"
      height="5"
      :fill="color === 'danger' ? '#DF7244' : '#5B6D79'"
    />
    <rect
      x="6"
      y="9"
      width="1"
      height="1"
      :fill="color === 'danger' ? '#DF7244' : '#5B6D79'"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'normal'
    }
  }
}
</script>
